<template>
  <div>
    <div class="web-view">
      <section class="white-sec">
        <div class="text-center">
          <div class="how-head">How it works</div>
        </div>
        <div class="container how-first">
          <div class="row">
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-1.png" />
            </div>
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-2.png" />
            </div>
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-3.png" />
            </div>
          </div>
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/2.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/3.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/4.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/5.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/6.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/7.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/8.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/9.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/10.png" />
        </div>
      </section>
    </div>

    <div class="mobile-view">
      <section class="white-sec">
        <div class="text-center">
          <div class="how-head">How it works</div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-1.png" />
            </div>
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-2.png" />
            </div>
            <div class="col-md-4 text-center">
              <img src="/assets/images/howitworks/1-3.png" />
            </div>
          </div>
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/4_Text.png" />
          <img src="/assets/images/howitworks/4_Image.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/5_Text.png" />
          <img src="/assets/images/howitworks/5_Image.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/6_Text.png" />
          <img src="/assets/images/howitworks/6_Image.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/7_Text.png" />
          <img src="/assets/images/howitworks/7_Image.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/8_Text.png" />
          <img src="/assets/images/howitworks/8_Image.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/9_Text.png" />
          <img src="/assets/images/howitworks/9_Image.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/10_Text.png" />
          <img src="/assets/images/howitworks/10_Image.png" />
        </div>
      </section>
      <section class="white-sec">
        <div class="container">
          <img src="/assets/images/howitworks/11_Text.png" />
          <img src="/assets/images/howitworks/11_Image.png" />
        </div>
      </section>
      <section class="gray-sec">
        <div class="container">
          <img src="/assets/images/howitworks/12_Text.png" />
          <img src="/assets/images/howitworks/12_Image.png" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "How it Works | KIPS LMS",
    titleTemplate: "",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  asyncData({ store, route }) {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
</style>